<template>
  <dash-page-new
      :title="$t('TestingProcess')"
      :root="entity.name"
      :mobile-title="entity.name"
      :mobile-side-title="$t('Navigation')"
  >

    <template #header_action>
      <div class="align-center"
           :class="[{'d-flex' : !SM}]"
      >

        <test-navigation
            :class="[{'mt-3' : SM}]"
            v-model="selectedQuestion"
            :questions="entity.questions"
            :all-answered="allAnswered"
            dropdown
        />
        <ws-button
            v-if="allAnswered"
            :to="businessDashLink('courses/test/result/' + entity.student_test_id)"
            label="FinishTest"
            :class="[{'ml-3' : !SM}, {'mt-3' : SM}]"
            :block="SM"
        >
        </ws-button>
      </div>

    </template>

    <template #default>
      <v-divider :class="[{'pb-5' : !SM}]" class="mt-3" :style="`border-color : ${wsBACKGROUND}`" />
      <!-- TESTING PROCESS    -->
      <div class="overflow-auto mx-auto pt-6" style="max-width: 780px" >

        <ws-timer
            v-if="startTimer"
            #default="{time}"
            @finish="testTimeOut"
            :seconds="timeLeft"
        >
          <v-sheet  :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLight py-2 mb-6">
            <h4 class="wsDARKER text-center d-flex justify-center align-center">
              <v-icon class="mr-2" :color="wsACCENT">mdi-clock-outline</v-icon>
              {{$t('TimeRemained')}}: {{ time }}
            </h4>
          </v-sheet>

        </ws-timer>

        <question-viewer
            :question="entity.questions[selectedQuestion] || {}"
            :order-number="selectedQuestion + 1"
            :course-id="entity.course_id"
            :test-id="entity.uuid"
            @answer="answerQuestion(entity.questions[selectedQuestion], $event)"
            @reset-answer="resetAnswer(entity.questions[selectedQuestion], $event)"
        />

        <test-navigation
            class="mt-6 "
            style="margin-bottom: 100px"
            v-model="selectedQuestion"
            :questions="entity.questions"
            :all-answered="allAnswered"
            @finish="$router.push(businessDashLink('courses/test/result/' + entity.student_test_id))"
        />


      </div>
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import testNavigation from "@/components/pages/westudy/tests/UI/testNavigation";
import questionViewer from "@/components/pages/westudy/tests/UI/questionViewer";
import tests from "@/mixins/tests";

export default {
  name: "DashTestPreview",
  props : ['uuid'],
  mixins : [tests],
  components : {
    testNavigation,
    questionViewer
  },
  data() {
    return {
      loading : false,
      entity : {
        name : '',
        questions : [],
        duration : 0,
      },
      testResult : {},
      testCompleted : false,
      selectedQuestion : 0,
      displayNavigation : false,
      timeLeft : 0,
      startTimer : false,
    }
  },
  computed : {
    allAnswered() {
      let result = true
      this.entity.questions.forEach((question) => {
        if (!this.QUESTION_HAS_REPLY(question)) { result = false }
      })
      return result
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_TEST_STUDENT',
        'REPLY_ANSWER',
        'RESET_ANSWER',
    ]),

    testTimeOut() {
      this.notify(this.$t('TestTimeFinished') , 'warning')
      this.$router.push(this.businessDashLink('courses/test/result/' + this.entity.student_test_id))
    },
    handleTestDuration() {
      if (!this.entity.duration) {
        return
      }


      let timeFromStart = this.entity.time_now - this.entity.time_started

      let duration = parseInt(this.entity.duration) * 60

      if ( timeFromStart > duration ) {
        this.testTimeOut()
        return
      }

      duration -= timeFromStart


      this.timeLeft = duration
      this.startTimer = true

    },
    updateAnswer(question, answer) {
      let questionIndex = this.entity.questions.findIndex(el => el.question_id === question.question_id)
      if (questionIndex === -1 ) {
        return
      }
      let answerIndex = this.entity.questions[questionIndex].answers.findIndex(el => el.uuid === answer.uuid)
      if (answerIndex === -1 ) {
        return
      }
      this.entity.questions[questionIndex].answers[answerIndex] = answer
      this.entity = this.COPY(this.entity)
    },
    async resetAnswer(question, answer) {
      let data = {
        question_id : question.question_id,
        answer_id : answer.uuid,
      }
      answer.reply = false
      answer.matched_reply = null
      this.updateAnswer(question,answer)
      let result = await this.RESET_ANSWER(data)
      if (!result) {
        return this.ERROR()
      }


    },
    answerQuestion(question,answer ) {
      this.loading = true
      let data = {
        uuid : question.question_id
      }
      if (question.type === 'default' || question.type === 'match') {
       data.answer_id = answer.uuid
      }
      if (question.type === 'true_false') {
        data.answer_bool = answer
      }
      if ( question.type === 'match') {
        data.matched_reply_answer_id = answer.matched_reply_answer_id
      }

      let buffer = this.COPY(answer)

      if ( question.type === 'default' && !question.multiple_type ) {
        question.answers.forEach((answer)=> { answer.reply = false })
        answer.reply = true
      } else if ( question.type === 'default' && question.multiple_type ) {
        answer.reply =  !answer.reply
      } else if ( question.type === 'true_false') {
        question.question_reply_bool = answer
      } else if ( question.type === 'match') {
        answer.reply = true
        answer.matched_reply = answer.matched_reply_answer_id
        let questionIndex = this.entity.questions.findIndex(el => el.question_id === question.question_id)
        if (questionIndex === -1 ) {
          return
        }
        let answerIndex = this.entity.questions[questionIndex].answers.findIndex(el => el.uuid === answer.uuid)
        if (answerIndex === -1 ) {
          return
        }
        this.entity.questions[questionIndex].answers[answerIndex] = answer
        this.entity = this.COPY(this.entity)
      }


      this.REPLY_ANSWER(data).then((out)=>{
        if ( !out.result ) {
          answer = buffer
          this.ERROR()
        }
        this.loading = false
      })

    },

    refreshTest() {
      this.initPage()
    },

    //technical
    getNavigationColor(question,i) {
      if ( this.selectedQuestion === i ) { return this.wsDARKER }
      return this.QUESTION_HAS_REPLY(question) ? this.wsATTENTION : ''
    },


    initPage() {
      if ( this.uuid ) {
        this.loading = true;
        this.GET_TEST_STUDENT(this.uuid).then((out)=>{
          if ( out.data ) {
            this.entity = out.data
            this.handleTestDuration()
          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(()=>{
          this.notify(this.$t('NetworkError'))
          this.loading = false })
      }
    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>